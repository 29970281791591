import React from 'react';
import PropTypes from 'prop-types';
import { withUnpublishedPreview } from 'gatsby-source-prismic';
import BlogArticlePage from 'templates/blog-article';
import ContentPage from 'templates/content';
import Shell from 'layout/shell/Shell';
import Button from 'organisms/button/Button';
import Masthead from 'organisms/masthead/Masthead';

import '../scss/main.scss';

const defaultProps = {};

const propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

function NotFoundPage({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pathname={pathname}
            seoTitle="Page Not Found"
        >
            <main className="l-grid__main" id="main-content">
                <Masthead
                    description="The page you are looking for could not be found."
                    heading="Page Not Found"
                />
                <Button buttonStyle="Primary" link={{ url: { link_type: "Document", type: "home", uid: "/" } }} linkText="Back to Home" />
            </main>
        </Shell>
    );
}

NotFoundPage.propTypes = propTypes;
NotFoundPage.defaultProps = defaultProps;

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
    templateMap: {
        blog_article: BlogArticlePage,
        content: ContentPage,
    },
})
