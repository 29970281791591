import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import PropTypes from 'prop-types';
import { renderComponents } from 'utility/renderBlogArticleComponents';
import Shell from 'layout/shell/Shell';
import Masthead from 'organisms/masthead/Masthead';

import '../scss/main.scss';

const defaultProps = {};

const propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

function BlogArticlePage({
    data,
    location: {
        pathname,
    },
}) {
    const doc = data?.prismicBlogArticle?.data;
    const pageDescription = doc?.page_meta_description?.raw?.[0]?.text;
    const pageSocialImage = doc?.page_social_share_image?.url;
    const siteSocialImage = data?.allPrismicSite?.nodes?.[0]?.data?.site_social_share_image?.url;
    const pageTitle = doc?.page_meta_title?.raw?.[0]?.text;
    const components = doc?.body;
    const mastheadDescription = doc?.ma_description?.raw?.[0]?.text;
    const mastheadHeading = doc?.ma_heading?.raw?.[0]?.text;
    const dateModified = doc?.ma_date_modified;
    const datePublished = doc?.ma_date_published;
    const datePublishedFormatted = doc?.ma_date_published_formatted;
    const structuredData = {
        '@context': 'http://schema.org',
        '@type': 'NewsArticle',
        author: {
            '@type': 'Person',
            name: 'Klarian',
        },
        dateModified: dateModified,
        datePublished: datePublished,
        description: mastheadDescription || pageDescription,
        headline: mastheadHeading || pageTitle,
        image: (pageSocialImage || siteSocialImage),
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': `${process.env.GATSBY_SITE_URL}${pathname}`,
        },
        publisher: {
            '@type': 'Organization',
            name: 'Klarian',
            logo: {
                '@type': 'ImageObject',
                url: `${process.env.GATSBY_SITE_URL}icons/icon-512x512.png`,
            },
        },
    };

    return (
        <Shell
            className="t-blog-article"
            pathname={pathname}
            seoDescription={pageDescription}
            seoImage={pageSocialImage}
            seoTitle={pageTitle}
        >
            <main className="l-grid__main t-blog-article__main" id="main-content">
                <Masthead
                    description={mastheadDescription}
                    heading={mastheadHeading}
                />
                <div className="l-grid l-grid--container t-blog-article__components">
                    <div className="t-blog-article__meta-container">
                        {datePublished && (
                            <time className="t-blog-article__meta-date" dateTime={datePublished}>{datePublishedFormatted}</time>
                        )}
                    </div>
                    {components?.map(component => (
                        renderComponents(component, component?.slice_type)
                    ))}
                </div>
            </main>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />
        </Shell>
    );
}

export const query = graphql`
    query($id: String!) {
        prismicBlogArticle(id: { eq: $id }) {
            data {
                body {
                    ... on PrismicBlogArticleBodyButton {
                        primary {
                            bu_style
                            bu_text {
                                raw
                            }
                            bu_url {
                                link_type
                                type
                                uid
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogArticleBodyContent {
                        primary {
                            co_content {
                                raw
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogArticleBodyImage {
                        primary {
                            im_caption {
                                raw
                            }
                            im_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogArticleBodyVideo {
                        primary {
                            vi_caption {
                                raw
                            }
                            vi_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            vi_video {
                                html
                                provider_name
                                thumbnail_url
                                title
                            }
                        }
                        slice_type
                    }
                }
                ma_date_modified
                ma_date_published
                ma_date_published_formatted: ma_date_published(formatString: "D MMMM YYYY")
                ma_description {
                    raw
                }
                ma_heading {
                    raw
                }
                page_meta_description {
                    raw
                }
                page_meta_title {
                    raw
                }
                page_social_share_image {
                    url
                }
            }
        }
        allPrismicSite {
            nodes {
                data {
                    site_social_share_image {
                        url
                    }
                }
            }
        }
    } 
`;

BlogArticlePage.propTypes = propTypes;
BlogArticlePage.defaultProps = defaultProps;

export default withPreview(BlogArticlePage);
