import React from 'react';
import Button from 'organisms/button/Button';
import Content from 'organisms/content/Content';
import Image from 'organisms/image/Image';
import Video from 'organisms/video/Video';

let index = 0;

function renderComponents(component, type) {
    switch (type) {
    case 'button':
        index++;
        return (
            <Button
                key={`${type}-${index}`}
                buttonStyle={'Secondary'}
                link={component?.primary?.bu_url}
                linkText={component?.primary?.bu_text?.raw?.[0]?.text}
            />
        );
    case 'content':
        index++;
        return (
            <Content
                key={`${type}-${index}`}
                content={component?.primary?.co_content?.raw}
            />
        );
    case 'image':
        index++;
        return (
            <Image
                key={`${type}-${index}`}
                caption={component?.primary?.im_caption?.raw}
                image={{
                    alt: component?.primary?.im_image?.alt,
                    dimensions: {
                        height: component?.primary?.im_image?.dimensions?.height,
                        width: component?.primary?.im_image?.dimensions?.width,
                    },
                    url: component?.primary?.im_image?.url,
                }}
                size=""
            />
        );
    case 'video':
        index++;
        const imageSize = (function(size) {
            switch(size) {
                case 'Normal':
                    return '&w=960';
                case 'Wide':
                    return '&w=1280';
                case 'Full Width':
                    return '&w=1920';
                default:
                    return '';
            }
        })(component?.primary?.vi_size);

        return (
            <Video
                key={`${type}-${index}`}
                caption={component?.primary?.vi_caption?.raw}
                size={component?.primary?.vi_size}
                videoEmbed={{
                    image: (component?.primary?.vi_image?.url) ? `${component?.primary?.vi_image?.url}${imageSize}` : component?.primary?.vi_video?.thumbnail_url,
                    html: component?.primary?.vi_video?.html,
                    providerName: component?.primary?.vi_video?.provider_name,
                    title: component?.primary?.vi_video?.title,
                }}
            />
        );
    default:
        return null;
    }
}

export { renderComponents };
